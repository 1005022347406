import React, { FC } from 'react';
import Section from 'evcharging/containers/layout/Section';
import Container from 'evcharging/components/Container';
import Spacer from 'evcharging/components/Spacer';
import useI18n from 'evcharging/hooks/useI18n';
import Paragraph from 'evcharging/components/Paragraph';
import Caption from 'evcharging/components/Caption';
import LinkButton from 'evcharging/components/LinkButton';
import Responsible from 'evcharging/components/Responsible';
import Box from 'evcharging/components/Box';
import EvcBrandItem from 'evcharging/containers/home-new/EvcBrandItem';
import {
  brand0,
  brand1,
  brand10,
  brand11,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9
} from 'assets/evc';


const EvcBrands: FC = () => {
  const { t } = useI18n();

  const items = [
    {
      image: brand0,
      title: 'Tesla',
    },
    {
      image: brand1,
      title: 'BTC',
    },
    {
      image: brand2,
      title: 'Tritium',
    },
    {
      image: brand3,
      title: 'ABB',
    },
    {
      image: brand4,
      title: 'ZEROVA',
    },
    {
      image: brand5,
      title: 'EnelX',
    },
    {
      image: brand6,
      title: 'AUTEL',
    },
    {
      image: brand7,
      title: 'Wallbox',
    },
    {
      image: brand11,
      title: 'EV Connect',
    },
    {
      image: brand8,
      title: 'SWITCH'
    },
    {
      image: brand9,
      title: 'ChargePoint',
    },
    {
      image: brand10,
      title: 'EVGateway',
    },
  ];

  return (
    <Section background={'light'}>
      <Container>
        <Caption>
          {t('home.leading-ev')}
        </Caption>
        <Spacer value={12} />
        <Paragraph size={'large'} textAlign={'center'} color={'middle'}>
          {t('home.pick-among')}
        </Paragraph>
        <Spacer value={36} />
        <Responsible fractions={Array(6).fill((100 / 6) - 1)} space={18}>
          {items.slice(0, 6).map((item) => (
            <EvcBrandItem {...item} key={item.title} />
          ))}
        </Responsible>
        <Spacer value={48} mobile={10} />
        <Responsible fractions={Array(6).fill((100 / 6) - 1)} space={18} justifyContent={'center'} gap={16}>
          {items.slice(6, 12).map((item, index) => (
            <Box key={item.title}>
              {index === 2 && <Spacer value={0} mobile={8} />}
              <EvcBrandItem {...item} />
            </Box>
          ))}
        </Responsible>
        <Spacer value={48} mobile={12} />
        <Paragraph textAlign={'center'}>
          <LinkButton href={'/evcharging/marketplace'}>{t('home.find-your')}</LinkButton>
        </Paragraph>
      </Container>
    </Section>
  );
};

export default EvcBrands;
