import React, { FC } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';

interface VideoModalProps {
  open: boolean;
  onToggle: () => void;
  YOUTUBE_VIDEO: string
}

const VideoModal: FC<VideoModalProps> = (props) => {
  const { open, onToggle, YOUTUBE_VIDEO } = props;
  const theme = useTheme();
  const styles = createStyles(theme);

  if (!open) {
    return null;
  }

  return (
    <div className={styles.root} onClick={onToggle}>
      <div className={styles.content}>
        <button className={styles.close} onClick={onToggle}>
          ✕
        </button>
        <iframe
          width={960}
          height={540}
          src={YOUTUBE_VIDEO}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    position: fixed;
    z-index: 1000;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    overflow: auto;
  `,
  content: css`
    position: relative;
    max-height: 80vh;
    overflow: auto;
    z-index: 1001;
    opacity: 1;

    & > iframe {
      width: 50rem;
      border: none;
      ${theme.breakpoints.mobile.down} {
        width: 100vw;
      }
    }
  `,
  close: css`
    position: absolute;
    text-align: center;
    cursor: pointer;
    padding: 0;
    font-size: 1.575rem;
    right: 8px;
    top: 8px;
    line-height: 1;
    outline: none;
    background: none;
    border: none;
    color: #ccc;
    transition: all ease-in 0.1s;

    :hover {
      font-size: 1.875rem;
    }
  `,
});

export default VideoModal;
