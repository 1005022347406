import React, { FC } from 'react';
import RequestConsultation from 'evcharging/containers/layout/RequestConsultation';
import Navigation from 'evcharging/containers/layout/Navigation';
import Page from 'evcharging/components/Page';
import { createGetServerSideProps, StaticPageProps } from 'evcharging/app';
import Footer from 'evcharging/containers/layout/Footer';
import { HOME_LINK } from 'evcharging/app/seo/pages';
import EvcHeader from 'evcharging/containers/home-new/EvcHeader';
import { I18nPage } from 'i18n/types/pages.enum';
import EvcBrands from 'evcharging/containers/home-new/EvcBrands';
import EvcWhy from 'evcharging/containers/home-new/EvcWhy';
import EvcPercentage from 'evcharging/containers/home-new/EvcPercentage';
import EvcDidYouKnow from 'evcharging/containers/home-new/EvcDidYouKnow';

const IndexPage: FC<StaticPageProps> = ({ page }) => (
  <Page
    locales={page.locales}
    title={page.meta_title}
    description={page.meta_description}
  >
    <Navigation />
    <EvcHeader />
    <EvcBrands />
    <EvcWhy />
    <EvcPercentage />
    <EvcDidYouKnow />
    <RequestConsultation />
    <Footer />
  </Page>
);

export const getServerSideProps = createGetServerSideProps(
  HOME_LINK,
  undefined,
  [I18nPage.EvChargingCommon, I18nPage.EvChargingHome, I18nPage.EnergyRates, I18nPage.Blog]
);

export default IndexPage;
