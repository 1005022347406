import React, { FC, ReactNode } from 'react';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import Spacer from 'evcharging/components/Spacer';
import LinkButton from 'evcharging/components/LinkButton';
import HorizontalLine from 'evcharging/components/HorizontalLine';
import SmallButton from 'evcharging/components/SmallButton';

interface EvcPercentageCardProps {
  label?: string;
  percent: string;
  title: string;
  description: ReactNode;
  bgImage?: string;
  text?: string;
  link?: string;
  onClick?: () => void;
  reverseTitle?: boolean;
}

const EvcPercentageCard: FC<EvcPercentageCardProps> = (props) => {
  const { reverseTitle = false, bgImage, label, text, percent, title, description, link, onClick } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { bgImage });

  return (
    <div className={styles.root}>
      <div className={styles.percent}>
        <label className={styles.label}>{label || <br />}</label>
        <div className={styles.highlight}>
          {reverseTitle && <span>{title}</span>} <div>{percent}</div> {reverseTitle || <span>{title}</span>}
        </div>
      </div>
      <HorizontalLine light />
      <Spacer value={19} />
      <div className={styles.description}>{description}</div>
      <Spacer value={32} />
      {onClick ? (
        <SmallButton fullWidth onClick={onClick}>{text}</SmallButton>
      ) : (
        link && <LinkButton fullWidth href={link}>{text}</LinkButton>
      )}
    </div>
  );
};

const createStyles = (theme: Theme, { bgImage }: Partial<EvcPercentageCardProps>) => ({
  root: css`
    position: relative;
    background: #f6f8f9;
    border: 1px #E5E9EB solid;
    border-radius: 6px;
    padding: 20px;
  `,
  percent: css`
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;

    @media (max-width: 1150px) {
      font-size: 27px;
    }

    ${theme.breakpoints.mobile.down} {
      font-size: 36px;
    }
  `,
  label: css`
    display: block;
    color: #303940;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    word-wrap: break-word
  `,
  highlight: css`
    display: flex;
    align-items: baseline;
    gap: 4px;
    color: ${theme.colors.accent};
    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  description: css`
    text-align: center;
    color: #303940;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    word-wrap: break-word
  `,
  link: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #fde69b;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 4px 12px;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #ae8704;

    ${theme.breakpoints.mobile.down} {
      font-size: 10px;
    }
  `
});

export default EvcPercentageCard;
