import React, { ButtonHTMLAttributes, FC } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import { CSSProperties } from '@emotion/serialize';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

type SubmitButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  CSSProperties & {
    fullWidth?: boolean;
  };

const LightButton: FC<SubmitButtonProps> = (props) => {
  const { fullWidth, height, ...rest } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { fullWidth });
  return <button className={clsx(styles.root, css({ height }))} {...rest} />;
};

const createStyles = (theme: Theme, { fullWidth = false }: Partial<SubmitButtonProps>) => ({
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px solid #F6F8F9;
    border-radius: 6px;
    height: 50px;
    padding: 0 16px;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #F6F8F9;
    transition: all 0.15s ease-in-out;
    outline: none;
    ${fullWidth && 'width: 100%;'}
  `,
});

export default LightButton;
