import React, { FC } from 'react';
import { css } from '@emotion/css';
import Link, { LinkProps } from 'evcharging/components/Link';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';

interface LinkButtonProps extends LinkProps {
  mobilePx?: number;
  fullWidth?: boolean;
}

const LinkButton: FC<LinkButtonProps> = (props) => {
  const { children, fullWidth, ...rest } = props;
  const theme = useTheme();
  const styles = createStyles(theme, props);

  return (
    <Link className={styles.root} {...rest}>
      {children}
    </Link>
  );
};

const createStyles = (theme: Theme, { mobilePx = 30, fullWidth = false }: LinkButtonProps) => ({
  root: css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.accent};
    border-radius: 6px;
    height: 50px;
    padding: 0 16px;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    font-weight: bold;
    font-size: 16px;
    color: #f6f8f9;
    transition: all 0.15s ease-in-out;
    ${fullWidth && 'width: 100%;'}
    ${theme.breakpoints.mobile.down} {
      height: 45px;
      padding: 0 ${mobilePx}px;
    }
  `,
});

export default LinkButton;
