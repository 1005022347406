import React, { FC } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import Spacer from 'evcharging/components/Spacer';

interface EvcBrandItem {
  image: string;
  title: string;
}

const EvcBrandItem: FC<EvcBrandItem> = (props) => {
  const { image, title } = props;
  const theme = useTheme();
  const styles = createStyles(theme, image);

  return (
    <div className={styles.root}>
      <div className={styles.image} />
      <Spacer value={8} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};

const createStyles = (theme: Theme, image: string) => ({
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  image: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 72px;
    border: 1px #E5E9EB solid;
    border-radius: 6px;
    background: #F6F8F9 url("${image}") center no-repeat;
    ${theme.breakpoints.mobile.down} {
      width: 100%;
    }
  `,
  title: css`
    color: #6E7C87;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    word-wrap: break-word
  `,
});

export default EvcBrandItem;
