import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';

interface ModalProps {
  open: boolean;
  onToggle: () => void;
  children: ReactNode;
}

const Modal: FC<ModalProps> = (props) => {
  const { open, onToggle, children } = props;
  const theme = useTheme();
  const styles = createStyles(theme);

  if (!open) {
    return null;
  }

  return (
    <div className={styles.root} onClick={onToggle}>
      <div className={styles.content}>
        <button className={styles.close} onClick={onToggle}>
          ✕
        </button>
        <div className={styles.cart}>
          <div className={styles.inner}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    position: fixed;
    z-index: 1000;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  `,
  content: css`
    position: relative;
    max-height: 80vh;
    overflow: auto;
    z-index: 1001;
    opacity: 1;
  `,
  cart: css`
    width: 505px;
    background: inherit;
    padding: 40px;
    ${theme.breakpoints.mobile.down} {
      width: 100vw;
      padding: 40px 12px;
    }
  `,
  inner: css`
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
  `,
  close: css`
    position: absolute;
    text-align: center;
    cursor: pointer;
    padding: 0;
    font-size: 1.575rem;
    right: 8px;
    top: 8px;
    line-height: 1;
    outline: none;
    background: none;
    border: none;
    color: #fff;
    transition: all ease-in 0.1s;

    :hover {
      font-size: 1.875rem;
    }
  `,
});

export default Modal;
