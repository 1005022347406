import React, { FC, ReactNode } from 'react';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

interface HighlightProps {
  children: ReactNode;
}

const Highlight: FC<HighlightProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const styles = createStyles(theme);

  return <span className={styles.root}>{children}</span>;
};

const createStyles = (theme: Theme) => ({
  root: css`
    color: ${theme.colors.accent};
  `,
});

export default Highlight;
