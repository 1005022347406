import React, { CSSProperties, FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

interface ParagraphProps extends CSSProperties {
  children: ReactNode;
  color?: 'dark' | 'middle' | 'light' | 'gray';
  size?: 'x-small' | 'small' | 'medium' | 'large';
}

const sizeMap = {
  'x-small': 16,
  small: 18,
  medium: 20,
  large: 24,
};

const sizeMapMobile = {
  'x-small': 14,
  small: 14,
  medium: 16,
  large: 18,
};

const Paragraph: FC<ParagraphProps> = (props) => {
  const { children, color, size, ...cssProps } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { color, size });

  return <div className={clsx(styles.root, css({ ...cssProps }))}>{children}</div>;
};

const createStyles = (
  theme: Theme,
  { color = 'dark', size = 'small' }: Partial<ParagraphProps>
) => ({
  root: css`
    font-weight: 400;
    font-size: ${sizeMap[size]}px;
    line-height: 150%;
    color: ${theme.colors.text[color]};
    ${theme.breakpoints.mobile.down} {
      font-size: ${sizeMapMobile[size]}px;
      line-height: 24px;
    }
  `,
});

export default Paragraph;
