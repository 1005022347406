import React, { FC } from 'react';
import { css } from '@emotion/css';

interface HorizontalLineProps {
  light?: boolean;
}

const HorizontalLine: FC<HorizontalLineProps> = (props) => {
  const { light = false } = props;
  return (
    <hr
      className={css({
        borderColor: light ? 'rgba(241,237,237,0.3)' : '#b0babf',
      })}
    />
  );
};

export default HorizontalLine;
