import React from 'react';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import Container from 'evcharging/components/Container';
import Responsible from 'evcharging/components/Responsible';
import LinkButton from 'evcharging/components/LinkButton';
import useI18n from 'evcharging/hooks/useI18n';
import Spacer from 'evcharging/components/Spacer';
import Paragraph from 'evcharging/components/Paragraph';
import { css } from '@emotion/css';
import bg from 'assets/evc/evc-why.png';
import { CONTACT_US_URL } from 'evcharging/app/seo/urls';

const items = [
  {
    label: 'Diverse Choices',
    text: 'Multiple options are just a click away.'
  },
  {
    label: 'Seamless Process',
    text: 'From selection to execution, we\'re your partner.'
  },
  {
    label: 'Maximized Savings',
    text: 'Harness up to 90% savings with unique incentives.'
  },
  {
    label: 'Complete Convenience',
    text: 'We handle all paperwork and processes.'
  },
  {
    label: 'Data-Driven',
    text: 'Navigate with insightful stats and analytics.'
  },
  {
    label: 'Professional Installations',
    text: 'Installing your chosen solutions seamlessly.'
  },
  {
    label: 'All-Inclusive Construction',
    text: 'We handle all construction aspects, from zero to completion.'
  },
  {
    label: 'Zero Stress',
    text: 'We manage the entire installation and construction phase.'
  }
];

const EvcWhy = () => {
  const { t } = useI18n();
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <div className={styles.root}>
      <Container>
        <Responsible fractions={[50, 50]}>
          <div>
            <Spacer value={40} mobile={0} />
            <p className={styles.title}>{t('home.why-energy5')}</p>
            <Spacer value={14} />
            <Paragraph color={'gray'} size={'large'} maxWidth={380}>
              {t('home.your-electrified')}
            </Paragraph>
            <Spacer value={48} mobile={24} />
            <LinkButton href={CONTACT_US_URL}>
              {t('home.discover-the')}
            </LinkButton>
          </div>
          <div className={styles.right}>
            <Spacer value={0} mobile={24}/>
            <Responsible fractions={[50, 50]}>
              <div>
                {items.slice(0, 4).map((item) => (
                  <div key={`item-${item.label}`}>
                    <div className={styles.label}>{item.label}</div>
                    <Paragraph color={'gray'} size={'x-small'} maxWidth={210}>
                      {item.text}
                    </Paragraph>
                    <Spacer value={24} />
                  </div>
                ))}
              </div>
              <div>
                {items.slice(4, 8).map((item, index) => (
                  <div key={`item-${item.label}`}>
                    <div className={styles.label}>{item.label}</div>
                    <Paragraph color={'gray'} size={'x-small'} maxWidth={[2,3].includes(index)? 260 : 210}>
                      {item.text}
                    </Paragraph>
                    <Spacer value={24} />
                  </div>
                ))}
              </div>
            </Responsible>
          </div>
        </Responsible>
      </Container>
    </div>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    background: #1A2024 url("${bg}") no-repeat center top;
    padding: 21px 0 72px;

    ${theme.breakpoints.mobile.down} {
      padding: 0;
    }
  `,
  title: css`
    color: #F9C106;
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;

    ${theme.breakpoints.mobile.down} {
      font-size: 24px;
    }
  `,
  label: css`
    color: #F9C106;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  right: css`
    padding-left: 120px;
    ${theme.breakpoints.mobile.down} {
      padding-left: unset;
    }
  `
});

export default EvcWhy;
