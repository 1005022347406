import React from 'react';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import Container from 'evcharging/components/Container';
import LinkButton from 'evcharging/components/LinkButton';
import useI18n from 'evcharging/hooks/useI18n';
import Spacer from 'evcharging/components/Spacer';
import Paragraph from 'evcharging/components/Paragraph';
import { css } from '@emotion/css';
import bg from 'assets/evc/did-you-know.png';
import Highlight from 'evcharging/components/Highlight';
import { CONTACT_US_URL } from 'evcharging/app/seo/urls';

const EvcDidYouKnow = () => {
  const { t } = useI18n();
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <div className={styles.root}>
      <Container>
        <Spacer value={40} mobile={0} />
        <p className={styles.title}>{t('home.did-you')}</p>
        <Spacer value={14} />
        <Paragraph color={'gray'} size={'large'} maxWidth={654}>
          {t('home.step-into')}
        </Paragraph>
        <Spacer value={44} />
        <Paragraph color={'light'} size={'large'} maxWidth={689}>
          <Highlight>{t('home.by-2025')}</Highlight>,&nbsp;
          {t('home.evs-are')}
        </Paragraph>
        <Paragraph color={'light'} size={'large'} maxWidth={755}>
          <Highlight>{t('home.ev-charging-stations')}</Highlight>,&nbsp;
          {t('home.can-boost')}
        </Paragraph>
        <Paragraph color={'light'} size={'large'} maxWidth={744}>
          <Highlight>{t('home.property-values')}</Highlight>,&nbsp;
          {t('home.can-jump')}
        </Paragraph>
        <Spacer value={44} />
        <Paragraph color={'gray'} size={'large'} maxWidth={707}>
          {t('home.stay-ahead')}
        </Paragraph>
        <Spacer value={48} mobile={24} />
        <LinkButton href={CONTACT_US_URL}>
          {t('home.get-started')}
        </LinkButton>
      </Container>
    </div>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    background: #1A2024 url("${bg}") no-repeat center top;
    padding: 21px 0 72px;

    ${theme.breakpoints.mobile.down} {
      padding: 24px 0;
    }
  `,
  title: css`
    color: #F9C106;
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;

    ${theme.breakpoints.mobile.down} {
      font-size: 24px;
    }
  `,
  label: css`
    color: #F9C106;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  right: css`
    padding-left: 120px;

    ${theme.breakpoints.mobile.down} {
      padding-left: unset;
    }
  `
});

export default EvcDidYouKnow;