import React, { useState } from 'react';
import Section from 'evcharging/containers/layout/Section';
import Container from 'evcharging/components/Container';
import PercentageCard from 'evcharging/containers/home/PercentageCard';
import Responsible from 'evcharging/components/Responsible';
import { EV_CALCULATOR_URL, EV_REBATES_URL, EV_TAX_CREDIT_URL } from 'evcharging/app/seo/urls';
import Modal from 'evcharging/components/Modal';
import Box from 'evcharging/components/Box';
import FaqRebatesItem from 'evcharging/containers/ev-rebates/FaqRebatesItem';
import useI18n from 'evcharging/hooks/useI18n';
import Spacer from 'evcharging/components/Spacer';
import { percent100k, percent20, percent2k, percent80 } from 'evcharging/assets/home';
import EvcPercentageCard from 'evcharging/containers/home-new/EvcPercentageCard';

const EvcPercentage = () => {
  const [open20, setOpen20] = useState<boolean>(false);
  const { t } = useI18n();
  const onToggle = () => setOpen20(!open20);
  const items = [
    {
      label: t('home.percentage1.label'),
      percent: '90%',
      title: t('home.percentage1.title'),
      description: (
        <>
          {t('home.percentage1.description1')}{' '}
          {t('home.percentage1.description2')}{' '}
          {t('home.percentage1.description3')}
        </>
      ),
      bgImage: percent80,
      text: t('home.percentage1.text'),
      link: EV_REBATES_URL,
    },
    {
      label: t('home.percentage2.label'),
      percent: 'Financing',
      title: t('home.percentage2.title'),
      description: (
        <>
          {t('home.percentage2.description1')}{' '}
          {t('home.percentage2.description2')}{' '}
          {t('home.percentage2.description3')}
        </>
      ),
      bgImage: percent20,
      text: t('home.percentage2.text'),
      link: '#',
      onClick: onToggle,
    },
    {
      label: t('home.percentage3.label'),
      percent: '$2K',
      title: t('home.percentage3.title'),
      description: (
        <>
          {t('home.percentage3.description1')}{' '}
          {t('home.percentage3.description2')}{' '}
          {t('home.percentage3.description3')}
        </>
      ),
      bgImage: percent2k,
      text: t('home.percentage3.text'),
      link: EV_CALCULATOR_URL,
    },
    {
      label: t('home.percentage4.label'),
      percent: '$100K',
      title: t('home.percentage4.title'),
      description: (
        <>
          {t('home.percentage4.description1')}{' '}
          {t('home.percentage4.description2')}{' '}
          {t('home.percentage4.description3')}
        </>
      ),
      bgImage: percent100k,
      text: t('home.percentage4.text'),
      link: EV_TAX_CREDIT_URL,
    },
  ];

  const items2 = [
    {
      percent: '30-60',
      title: t('home.percentage5.title'),
      description: (
        <>
          {t('home.percentage5.description1')}
        </>
      ),
    },
    {
      percent: '2035',
      title: t('home.percentage6.title'),
      description: (
        <>
          {t('home.percentage6.description1')}
        </>
      ),
      reverseTitle: true,
    },
    {
      label: t('home.percentage7.label'),
      title: '',
      percent: t('home.percentage7.percent'),
      description: (
        <>
          {t('home.percentage7.description1')}
        </>
      ),
    },
    {
      percent: '58%',
      title: t('home.percentage8.title'),
      description: (
        <>
          {t('home.percentage8.description1')}
        </>
      ),
    },
  ];

  return (
    <>
      <Modal open={open20} onToggle={onToggle}>
        <Box padding={24}>
          <FaqRebatesItem
            question={t('home.percentage2.text')}
            answer={[t('home.percentage2.answer1'), t('home.percentage2.answer2')]}
          />
        </Box>
      </Modal>
      <Section>
        <Container>
          <Responsible fractions={Array(4).fill(23.6)} space={56}>
            {items.map((item, index) => (
              <EvcPercentageCard {...item} key={index} />
            ))}
          </Responsible>
          <Spacer value={32} />
          <Responsible fractions={Array(4).fill(23.6)} space={56}>
            {items2.map((item, index) => (
              <EvcPercentageCard {...item} key={index} />
            ))}
          </Responsible>
        </Container>
      </Section>
    </>
  );
};

export default EvcPercentage;
