import React, { FC, ReactNode } from 'react';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

interface CaptionProps {
  children: ReactNode
}

const Caption: FC<CaptionProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const styles = createStyles(theme);

  return (
    <div className={styles.root}>
      {children}
    </div>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    color: #252C32;
    text-align: center;
    ${theme.breakpoints.mobile.down} {
      font-size: 24px;
    }
  `,
});

export default Caption;