export const installation = require('./installation.webp');
export const slide01 = require('./slide01.webp');
export const slide02 = require('./slide02.webp');
export const slide03 = require('./slide03.webp');
export const smallDecor = require('./smallDecor.png');

export const largeDecor = require('./largeDecor.png');
export const mediumDecor = require('./mediumDecor.png');
export const percent100k = require('./percent100k.png');
export const percent2k = require('./percent2k.png');
export const percent20 = require('./percent20.png');
export const percent80 = require('./percent80.png');
export const saveBigTime = require('./saveBigTime.png');
export const software = require('./software.png');
export const highlightDecore = require('./highlightDecore.png');
export const e1 = require('./e1.png');
export const e2 = require('./e2.png');
export const e3 = require('./e3.png');
export const effortlessDesktop = require('./effortlessDesktop.png');
export const effortlessMobile = require('./effortlessMobile.png');
export const perfect1 = require('./perfect1.png');
export const perfect2 = require('./perfect2.png');
export const perfect3 = require('./perfect3.png');
export const perfect4 = require('./perfect4.png');
export const perfect5 = require('./perfect5.png');
export const perfect6 = require('./perfect6.png');
export const mapcity = require('./mapcity.png');
export const mapslogos = require('./mapslogos.png');
export const appStore = require('./appStore.png');
export const googlePlay = require('./googlePlay.png');
export const effortless1 = require('./effortless1.png');
export const effortless2 = require('./effortless2.png');
export const effortless3 = require('./effortless3.png');
export const compatible = require('./compatible.png');
