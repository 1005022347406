import React, { FC, useRef, useState } from 'react';
import Spacer from 'evcharging/components/Spacer';
import VideoModal from 'evcharging/containers/home/VideoModal';
import {DESKTOP_VIDEO, YOUTUBE_VIDEO} from 'evcharging/app';
import EvcVideo from 'evcharging/containers/home-new/EvcVideo';

const EvcHeader: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const desktopRef = useRef<HTMLVideoElement>(null);
  const mobileRef = useRef<HTMLVideoElement>(null);

  const onToggle = () => {
    if (desktopRef.current && mobileRef.current) {
      if (open) {
        desktopRef.current.play().then();
        mobileRef.current.play().then();
      } else {
        desktopRef.current.pause();
        mobileRef.current.pause();
      }
    }
    setOpen(!open);
  };

  return (
    <>
      <VideoModal open={open} onToggle={onToggle} YOUTUBE_VIDEO={YOUTUBE_VIDEO} />
      <Spacer value={156} mobile={68} />
      <EvcVideo src={DESKTOP_VIDEO} openFullVideo={onToggle} ref={desktopRef} />
    </>
  );
};

export default EvcHeader;
