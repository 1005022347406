import React, { FC, ReactNode, useMemo } from 'react';
import { css } from '@emotion/css';
import Spacer from 'evcharging/components/Spacer';
import Box from 'evcharging/components/Box';

export interface FaqRebatesItemProps {
  question: string;
  answer: Array<string | [string, string]>;
  more?: Array<string | [string, string]>;
  onMore?: (content: ReactNode) => void;
}

const FaqRebatesItem: FC<FaqRebatesItemProps> = (props) => {
  const { answer, question, onMore, more } = props;
  const btn = 'More info >';
  const moreContent = useMemo(
    () =>
      more ? (
        <Box padding={24}>
          <FaqRebatesItem question={question} answer={more} />
        </Box>
      ) : null,
    [more, question]
  );

  return (
    <div>
      <h4 className={styles.answer}>{question}</h4>
      <Spacer value={12} />
      {answer.map((item) =>
        Array.isArray(item) ? (
          <p className={styles.question}>
            <b>{item[0]}</b>
            {item[1]}
          </p>
        ) : (
          <p className={styles.question}>{item}</p>
        )
      )}
      {moreContent && (
        <button className={styles.more} onClick={() => onMore && onMore(moreContent)}>
          {btn}
        </button>
      )}
    </div>
  );
};

const styles = {
  answer: css`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.019em;
    color: #252c32;
  `,
  question: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    color: #6e7c87;
    padding-bottom: 12px;
  `,
  more: css`
    color: #f9c106;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    background: none;
    cursor: pointer;
  `,
};

export default FaqRebatesItem;
